<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  data() {
    return {
      types: null,
    };
  },
  methods: {
    getList() {
      api.get("investments/types").then((response) => {
        if (response.data.status == "success") {
          this.types = response.data.list;
        }
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Tipos de Investimento") }}</h4>
        </div>
      </div>
    </div>
    <div v-if="types" class="row">
      <div class="col-lg-4" v-for="(type, id) in types" :key="id">
        <div class="card">
          <div class="card-body p-4">
            <img
              v-if="type.cover"
              class="type-cover rounded mb-4"
              :src="type.cover"
            />
            <h5 class="text-default mb-4">
              <strong>{{ type.name }}</strong>
            </h5>
            <div class="mb-4" v-html="type.description"></div>
            <h5 class="mb-0"><strong>Captados</strong></h5>
            <h5 class="text-default mb-4">
              <strong>{{ type.value_captured | currency }}</strong>
            </h5>
            <h5 class="mb-0"><strong>Alvo</strong></h5>
            <h5 class="text-default mb-4">
              <strong>{{ type.value_total | currency }}</strong>
            </h5>
            <h5 class="mb-0"><strong>Investidores</strong></h5>
            <h5 class="text-default mb-4">
              <strong>{{ type.value_captured_contracts }}</strong>
            </h5>
            <p class="text-default mb-0 font-size-12">Progresso da Captação</p>
            <b-progress
              style="border-radius: 3px"
              :value="Number(type.value_captured_percent)"
              variant="default"
              height="15px"
            ></b-progress>
            <div class="mt-4">
              <router-link
                :to="'/investments/types/' + type.id"
                class="btn btn-default"
              >
                Investir
              </router-link>
              <a v-if="type.pdf" class="btn btn-light ml-2" :href="type.pdf">+ Detalhes</a>
              <a v-else class="btn btn-light ml-2 disabled">+ Detalhes</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <b-spinner
        small
        class="ml-2 align-middle"
        variant="default"
        role="status"
      ></b-spinner>
    </div>
  </Layout>
</template>

<style scoped>
.type-cover {
  object-fit: cover;
  aspect-ratio: 16 / 9 auto;
  width: 100%;
}
</style>
